import React, { useState, useEffect, useRef } from "react";
import styles from "./AllNotifications.module.css";
import notificationBlue from "/assets/topbar/Notification_Blue.svg";
import notificationGrey from "/assets/topbar/Notification_Grey.svg";
import Button from "../../UI/Button/Button";

const AllNotifications = () => {
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [hasNewNotifications, setHasNewNotifications] = useState(false);
    const [isVisible, setIsVisible] = useState(true); // To control visibility
    const containerRef = useRef(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setNotifications([
                { id: 1, message: "TRC 2024 application submitted", time: "5min", status: "new" },
                { id: 2, message: "New comment on your post", time: "10min", status: "read" },
                { id: 3, message: "Your order has been shipped", time: "15min", status: "new" },
                { id: 4, message: "Password changed successfully", time: "20min", status: "read" },
                { id: 5, message: "New login detected", time: "30min", status: "new" },
                { id: 6, message: "New comment on your post", time: "10min", status: "read" },
                { id: 7, message: "Your order has been shipped", time: "15min", status: "new" },
            ]);
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    // Update the hasNewNotifications state whenever the notifications change
    useEffect(() => {
        setHasNewNotifications(notifications.some((notification) => notification.status === "new"));
    }, [notifications]);

    // Sort notifications
    const sortedNotifications = [...notifications].sort((a, b) => {
        if (a.status === b.status) {
            return parseInt(a.time) - parseInt(b.time);
        }
        return a.status === "new" ? -1 : 1;
    });

    // Handle "Mark all as read"
    const markAllAsRead = () => {
        const updatedNotifications = notifications.map((notification) => ({
            ...notification,
            status: "read",
        }));
        setNotifications(updatedNotifications);  // Update notifications
    };

    if (!isVisible) return null; // Return null if the component is not visible

    return (
        <div className={styles.container} ref={containerRef}>
            <div className={styles.header}>
                <p className={styles.header_title}>Notifications</p>
                {hasNewNotifications && (
                    <Button
                        className={`${styles.mark_as_all_read} ${styles.new}`}
                        onClick={markAllAsRead}
                    >
                        Mark all as read
                    </Button>
                )}
            </div>
            <div className={styles.list_container}>
                <ul>
                    {loading
                        ? Array(5)
                            .fill(0)
                            .map((_, index) => (
                                <li key={index} className={styles.list_item}>
                                    <div className={styles.notification_item}>
                                        <div className={styles.skeleton_avatar}></div>
                                        <div className={styles.skeleton_details}>
                                            <div className={styles.skeleton_line_short}></div>
                                            <div className={styles.skeleton_line_long}></div>
                                        </div>
                                    </div>
                                </li>
                            ))
                        : sortedNotifications.map((notification) => (
                            <li key={notification.id} className={styles.list_item}>
                                <div className={styles.notification_item}>
                                    <div className={styles.first_part}>
                                        <div
                                            className={`${styles.avatar} ${notification.status === "new"
                                                ? styles.new
                                                : styles.read
                                                }`}
                                        >
                                            <img
                                                src={notification.status === "new"
                                                    ? notificationBlue
                                                    : notificationGrey}
                                                alt="Notification Icon"
                                            />
                                        </div>
                                        <div className={styles.details}>
                                            <p className={styles.list_name}>{notification.message}</p>
                                            <p className={styles.list_date}>{notification.time}</p>
                                        </div>
                                    </div>
                                    <div>
                                        {notification.status === "new" && (
                                            <p className={styles.new_status}></p>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
};

export default AllNotifications;


