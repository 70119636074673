import { useState, useRef } from "react";
import styles from "./TopNavBar.module.css";
import AccountDropDown from "./AccountDropDown";
import Button from "../../UI/Button/Button";
import accountIcon from "/assets/topbar/AccountProfile.svg";
import dropdownProfile from "/assets/topbar/DropdownProfile.svg";
import notificationIcon from "/assets/topbar/NotificationBell.svg";
import AllNotifications from "./AllNotifications";
import { useOutsideClick } from "../../../hooks/use-outsideClick";
import { useSelector } from "react-redux";

const TopNavBar = ({ ...props }) => {
  const [showAccountDropDown, setShowAccountDropDown] = useState(false);
  const [hasNewNotification, setHasNewNotification] = useState(true);
  const [showAllNotifications, setShowAllNotifications] = useState(false);

  const { userDetails } = useSelector((state) => state.auth);

  const accountRef = useRef(null);
  const notificationRef = useRef(null);

  useOutsideClick({
    ref: accountRef,
    handler: () => setShowAccountDropDown(false),
  });

  useOutsideClick({
    ref: notificationRef,
    handler: () => setShowAllNotifications(false),
  });

  const handleAccountClick = () => {
    setShowAccountDropDown(!showAccountDropDown);
  };

  const handleNotificationClick = () => {
    // setShowAllNotifications(!showAllNotifications);
    // setHasNewNotification(false);
  };

  return (

    <div className={styles.topbar}>
      <div ref={notificationRef}>
        <Button className={styles.notification_action} onClick={handleNotificationClick} disabled={true}>
          <img src={notificationIcon} alt="Notification Bell" />
          {hasNewNotification && <p className={styles.notification_circle}></p>}
        </Button>

        {showAllNotifications && (
          <div className={styles.all_notification_container}>
            <AllNotifications />
          </div>
        )}
      </div>

      <div ref={accountRef}>
        <Button
          className={styles.account_action}
          onClick={handleAccountClick}
        >
          <img src={accountIcon} alt="" />
          <p className={styles.label}>{userDetails?.userName}</p>
          <img src={dropdownProfile} alt="" />
        </Button>
        {showAccountDropDown && (
          <AccountDropDown onLogout={props.onLogout} />
        )}
      </div>
    </div>
  );
};

export default TopNavBar;

